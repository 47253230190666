import { BrowserRouter as Router } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import "./styles/App.css";
import "./styles/styles.css";
import "./styles/icons.css";
import "./styles/bootstrap.3.3.7.min.css"
import { DLPLayout } from "./DLP/DLPLayOut";
import { useEffect, useState } from "react";
import Navbar from 'react-bootstrap/Navbar'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import { loginRequest } from "./authConfig";
import { Container, Nav, NavDropdown } from "react-bootstrap";
import { EQCTabs } from "./EQC/EQCTabs";
import Button from "react-bootstrap/Button";
import EQCApp from "./EQC/EQCApp";

/**
 * msal-react is built on the React context API and all parts of your app that require authentication must be 
 * wrapped in the MsalProvider component. You will first need to initialize an instance of PublicClientApplication 
 * then pass this to MsalProvider as a prop. All components underneath MsalProvider will have access to the 
 * PublicClientApplication instance via context as well as all hooks and components provided by msal-react. For more, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);
const App = ({ instance, account }) => {
  const [loginUserName, setUserName] = useState(null);


  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts();
  console.log("check " + accounts)
  useEffect(() => {
    const timer = setTimeout(() => {
      //setCount('Timeout called!');
      console.log("timeout" + msalInstance.getAllAccounts());
      // eslint-disable-next-line react-hooks/exhaustive-deps
      account = msalInstance.getAllAccounts();
        if (account.length > 0) {
          debugger;
        setUserName(account[0].name)
      }
    }, 2000);
    return () => clearTimeout(timer);
    // if (account) {
    //   console.log('changed')
    //   if (account.length > 0) {
    //     setUserName(account[0].name)
    //   }
    // }
  }, [account]);
  console.log(account);
  return (
    <Router>
      <MsalProvider instance={instance}>
        <header>
          <div className="header-Info">
            <AuthenticatedTemplate>

              <Navbar className="user-dropdown">
                <Container fluid>

                  <Navbar.Toggle aria-controls="navbar-dark-example" />
                  <Navbar.Collapse id="navbar-dark-example">
                    <Nav>
                      <NavDropdown
                        id="nav-dropdown-dark-example"
                        title={loginUserName}                     >
                        <NavDropdown.Item href="MyProfile">Profile</NavDropdown.Item>

                        <NavDropdown.Divider />
                        <NavDropdown.Item onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>
                          Sign Out</NavDropdown.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Container>
              </Navbar>

            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              {() => instance.loginRedirect(loginRequest)}

            </UnauthenticatedTemplate>

          </div></header>


        {/* <DLPLayout /> */}

        <EQCTabs >
          {/* <div className="header-Info">
            <AuthenticatedTemplate>
              <Button variant="secondary" className="ml-auto" onClick={() => instance.logoutRedirect({ postLogoutRedirectUri: "/" })}>Sign Out</Button>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
              {() => instance.loginRedirect(loginRequest)}

            </UnauthenticatedTemplate>
          </div> */}
          <div className="page-load">
            <EQCApp account={accounts}/>
          </div>
        
        </EQCTabs> 


        {/* <DLPTabs>

          <div className="page-load">
            <DLPApp />
          </div>
        </DLPTabs> */}
        {/* <DLPTabs>
               
            </DLPTabs> */}

      </MsalProvider>
    </Router>
  );
}

export default App;