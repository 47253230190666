import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import Button from "react-bootstrap/Button";
import paginationFactory from "react-bootstrap-table2-paginator";
import filterFactory, {
  textFilter,
  selectFilter,
} from "react-bootstrap-table2-filter";
import ToggleButton from "react-bootstrap/ToggleButton";
import { uiConfig } from "./config";
import styled from 'styled-components';

const customTotal = (from, to, size) => (
  <span className="react-bootstrap-table-pagination-total margin-left-10">
    Showing {from} to {to} of {size} Results
  </span>
);

let checkpointStatusFilter;
const checkpointStatusOptions = {
  true: "Enabled",
  false: "Disabled",
};

export const QC_Checkpoints = () => {
  debugger;
  const [todos, setTodos] = useState([]);
  const [checked, setChecked] = useState(false);

  // bootstrap table options
  const options = {
    paginationSize: 5,
    pageStartIndex: 1,
    // alwaysShowAllBtns: true, // Always show next and previous button
    // withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: "First",
    prePageText: "Back",
    nextPageText: "Next",
    lastPageText: "Last",
    nextPageTitle: "First page",
    prePageTitle: "Pre page",
    firstPageTitle: "Next page",
    lastPageTitle: "Last page",
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
    sizePerPageList: [
      {
        text: "15",
        value: 15,
      },
      {
        text: "30",
        value: 30,
      },
      {
        text: "All",
        value: todos.length,
      },
    ], // A numeric array is also available. the purpose of above example is custom the text
  };

  // table columns
  const columns = [
    {
      dataField: "id",
      text: "Checkpoint ID",
      filter: textFilter(),
    },

    {
      dataField: "descriptions",
      text: "Descriptions",
      filter: textFilter(),
    },
    {
      dataField: "regions",
      text: "Region",
      filter: textFilter(),
      hidden: true,
    },

    {
      dataField: "enabled",
      text: "Status",
      filter: textFilter(),
      filter: selectFilter({
        options: checkpointStatusOptions,
        getFilter: (filter) => {
          checkpointStatusFilter = filter;
        },
      }),
      formatter: annotationFormatter,
    },
  ];

  // initial data load
  useEffect(() => {
    fetchTodos();
  }, []);

  const fetchTodos = async () => {
    const response = await fetch(uiConfig.apiURL + "/get_all_checkpoints");
    const todos = await response.json();
    //passing data to bootstrap table variable
    setTodos(todos.data);
  };

  const change_checkpoint_status = async (row) => {
    const formData = new FormData();
    debugger;
    formData.append("checkpoint_id", row.id);
    formData.append("enabled", row.enabled);

    const options = {
      method: "POST",
      body: formData,
    };

    const response = await fetch(
      uiConfig.apiURL + "/update_checkpoint_status",
      options
    );
    const responseMessage = await response.json();
    fetchTodos();
  };

  // overriding the dom element to display button
  function btn_enable_formatter(cell, row) {
    return;

    <a>Testr</a>;
  }

  function annotationFormatter(cell, row) {
    return (
      <Button
        variant={row["enabled"] == true ? "primary" : "secondary"}
        onClick={() => {
          change_checkpoint_status(row);
        }}
        className="margin-bottom-10"
      >
        {row["enabled"] == true ? "Disable" : "Enable"}
      </Button>
    );

    //return <button variant="primary" href='#' target="_blank" className="margin-bottom-10" > {row['enabled'] == true? "Enabled":"Disabled"}</button >;
  }

  return (
    <>


      <div className="card border-0">
        <div className="card-body shadow-sm">
          <div>
            <BootstrapTable
              keyField="ID"
              data={todos}
              columns={columns}
              pagination={paginationFactory(options)}
              filter={filterFactory()}
            />
          </div>
        </div>
      </div>
    </>
  );
};
