import { AuthenticatedTemplate } from "@azure/msal-react";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import logo from "./../styles/images/aim-logo-white.png";
import { NavLink } from "react-router-dom";
import { Drawer, DrawerNavigation } from "react-bootstrap-drawer";

const ApplicationDrawer = (props) => {
  // const [open, setOpen] = useState(false);

  // const handleToggle = () => setOpen(!open);

  return (
    <>
      <Drawer {...props}>
        <a className="navbar-brand" href="/">
          <img src={logo} alt="BigCo Inc. logo" className="img-30" />
        </a>
        <DrawerNavigation>
          <ul>
            <li>
              <NavLink activeClassName="active" to="/eqc">
                <i class="icon icon-upload"></i>
                QC Automation
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/requests">
                <i class="icon icon-sort-down"></i>
                Requests
              </NavLink>
            </li>
            <li>
              <NavLink activeClassName="active" to="/checkpoints">
                <i class="icon icon-settings"></i>
                Checkpoints
              </NavLink>
            </li>
          </ul>
        </DrawerNavigation>
      </Drawer>
    </>
  );
};

export const EQCTabs = (props) => {
  return (
    <>
      <Container fluid className="container-page">
        <Row className="flex-xl-nowrap height-100">
          <AuthenticatedTemplate>
            <Col
              as={ApplicationDrawer}
              xs={12}
              md={3}
              lg={2}
              className="side-bar"
            />
          </AuthenticatedTemplate>
          <Col xs={12} md={9} lg={10}>
            {props.children}
          </Col>
        </Row>
      </Container>
    </>
  );
};
